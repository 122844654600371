@media screen and (min-width:2419) {
    body{
        section{
            .container{
                width: 55%;

                p,a,li{
                    font-size: 1.1rem !important;
                }

                .quick_access{
                    .flexbox{
                        a{
                            
                            p{
                                font-size: .9rem !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            
                        }
                    }
                }
            }

            
        }
    }
}

@media screen and (max-width:1500) {
    body{
        section{
            .container{
                width: 80%;
            }
        }
    }
}

@media screen and (max-width:1271px) {
    body{
        
      .dash_body{
        .dash_container{
            .dash_nav{
                .flexbox{
                    form{
                        width: 45%;
                    }

                    .dash_actions{
                        width: 30%;
                    }
                }
                
            }
        }
      }
    }
}

@media  only screen and (max-width:900px) {
   body{
    section{
        
        .container{
            width: 90% !important;

            .members_resources{
                .flexbox{
                    flex-direction: column;
                    .flex{
                        width: 100% !important;
                        padding-top: 30px;

                        aside{
                            margin-top: auto;
                            height: 100px;
                            overflow: hidden;
                            transition: .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                            border: 1px solid rgba(128, 128, 128, 0.426);
                            ul{
                                li{
                                    .resource_dropdown{
                                        display: block !important;
                                    }
                                }
                            }
                        }

                        .aside_dropdown{
                            height:620px;
                        }
                    }
                }
            }

            nav{
            .flexbox{
                justify-content: center;
                .flex:nth-child(1){
                    width: 60%;
                    .logo{
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 100%;
                        }
                    }

                    .code{
                        h3{
                            font-size: 1rem;
                        }
                    }
                }

               .flex:nth-child(2){
                width: 50%;
                justify-content: flex-end;
                ul:nth-child(1){
                    display: none;
                }

                ul:nth-child(2){
                    padding:0 5%;

                    li{
                        padding: 10px;
                    }
                }
                
                .material-symbols-outlined {
                display: block;
                font-size: 2rem;
                font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48
                }
               }

                
            }
        }

        .banner{
            .flex{
                padding-bottom: 30px;
                .img{
                    display: none;
                }

                .content{
                    width: 100%;
                    h1{
                        font-size:2rem;
                        line-height: 1.3;
                        margin-top: 7px;
                    }

                    p{
                        margin: 30px 0;
                    }
                }
            }
        }

        .main_section{
           .meeting{
            p{
                &:nth-child(2){
                    width: 90%;
                }
            }
           }
            .news_announcments{
                .flexbox{
                    .flex{
                        width: 100%;

                        .content{
                            .img{
                                min-height: 23vh !important;
                            }
                        }
                    }
                }
            }
            .highlights{
                flex-direction: column;
                padding: 50px 10px;

                .flex:nth-child(1){
                    width: 100%;

                    .content{
                        width: 100%;
                        p{
                            line-height: 1.7;
                            font-size: rem;
                        }

                        button{
                            width: 100%;
                        }
                        
                    }
                }

                .flex:nth-child(2){
                    margin-top: 30px;

                    .content{
                        ul{
                            padding-left: 30px;
                        }
                    }
                }
            }
        }

        .quick_access{
            overflow-x: scroll;
            scroll-snap-type:x mandatory;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
           
            .flexbox{
                width: 300%;

                .flex{
                     scroll-snap-align: center;
                }
                 
            }
        }

        .contact{
            
            .flexbox{
                flex-direction: column;
                .flex{
                    width: 100% !important;

                    .content{
                        width: 100%;

                        p{
                            font-size: .9rem;
                        }
                    }

                    
                }

                .flex:nth-child(2){
                    justify-content: flex-start !important;
                    margin-top: 50px;
                    .img{
                        width: 100%;
                    }
                }
            }
        }

        .list_container{
            .executives{
                gap: 10px;
                .exec_name{
                    width: 40% !important;
                    font-size: .9rem !important;
                    p{
                        display: block !important;
                    }
                }
                .exec_position{
                    display: none;
                }

                .exec_email{
                    font-size: .9rem;
                }
            }
        }

       

        }

         footer{
            padding: 20px 0;
            .container{
                width: 85%;
                .flexbox{
                    flex-direction: column;

                    .flex{
                        width: 100%;
                        .content{
                            ul{
                                .admin_link{
                                    display: none;
                                }
                            }
                        }
                    }

                    .flex:nth-child(2),.flex:nth-child(3){
                        justify-content: flex-start;
                    }
                }
            }
        }
        
    }
        
   }
    
}

@media only screen and (max-width:400px){
    body{
        section{
            .container{
                nav{
                    .flexbox{
                        .flex:nth-child(1){
                            width: 50%;

                            .code{
                                h3{
                                    font-size: .9rem;
                                }
                                
                            }
                        }
                    }
                }

                .main_section{
                    .meeting{
                        padding: 10px;

                        h1{
                            margin: 30px 0;
                        }

                        p{
                            font-size: .9rem;
                        }
                    }

                    .news_announcments{
                        .flexbox{
                            .flex{
                                .content{
                                    p{
                                        display: none;
                                    }

                                    .img{
                                        iframe{
                                            height: 32vh;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}